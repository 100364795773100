/*
    Manages Overlays
    ----------------------------------------------
*/

.page, .sidebar {
  position: relative;
}
.page {
  z-index: 2;
}
.sidebar {
  z-index: 1;

  // Ensures that sidebar menus appear above the rest of the app on hover
  &:hover {z-index: 2;}
  &:hover + .page {z-index: 1;}
}

// Make Overlay Technology full screen
.overlay-technology {
  left: -($sidebar--width) !important;

  // Hacky way to ensure proper appearance of file upload modal
  // Needed to have a this div nested inside of itself for the
  // Drag & drop feature to work correctly
  .overlay-technology {
    left: 0 !important;
    &:before {display: none;}
  }
}
