/*
  static Legend
  ------------------------------------------------------------------------------
  Seen in a dashboard cell, below the graph
  NOTE: Styles for the parent are stored in Javascript, in staticLegend.js
*/

.static-legend {
  position: absolute;
  width: calc(100% - 32px);
  bottom: 8px;
  left: 16px;
  display: flex;
  padding-top: 8px;
  align-items: flex-end;
  flex-wrap: wrap;
  max-height: 50%;
  overflow: auto;
  @include custom-scrollbar($g3-castle,$g6-smoke);
}
.static-legend--dot {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $g20-white;
}
.static-legend--item,
.static-legend--single {
  height: 22px;
  line-height: 22px;
  white-space: nowrap;
  background-color: $g4-onyx;
  border-radius: 3px;
  color: $g20-white;
  font-size: 12px;
  font-weight: 600;
  padding: 0 7px;
  margin: 1px;
}
.static-legend--item {
  transition: background-color 0.25s ease, color 0.25s ease;

  span,
  .static-legend--dot {
    opacity: 0.8;
    transition: opacity 0.25s ease;
  }

  &:hover {
    cursor: pointer;
    background-color: $g6-smoke;

    span,
    .static-legend--dot {
      opacity: 1;
    }
  }
  &.disabled {
    background-color: $g1-raven;
    font-style: italic;

    span,
    .static-legend--dot {
      opacity: 0.35;
    }

    &:hover {
      background-color: $g2-kevlar;

      span,
      .static-legend--dot {
        opacity: 0.65;
      }
    }
  }
}
