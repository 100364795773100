/*
  Styles for the Manage Organizations Page
  ------------------------------------------------------------------------------
  Is not actually a table
*/

$orgs-table--active-width: 102px;
$orgs-table--public-width: 90px;
$orgs-table--default-role-width: 130px;
$orgs-table--delete-width: 30px;

.orgs-table--name {
  flex: 1 0 0;
}
.orgs-table--public {
  width: $orgs-table--public-width;
  text-align: center;
}
.orgs-table--default-role {
  width: $orgs-table--default-role-width;
}
.orgs-table--delete {
  width: $orgs-table--delete-width;
}
.orgs-table--active {
  width: $orgs-table--active-width;
  justify-content: center;
  @include no-user-select();
  
  .btn {width: 100%;}
}
.orgs-table--default-role.deleting {
  width: (
    $orgs-table--default-role-width - $fancytable--table--margin -
      $orgs-table--delete-width
  );
}
.orgs-table--public-toggle {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $g4-onyx;
  border-radius: 4px;
  position: relative;

  > .slide-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.disabled {
    color: $g9-mountain;
    @include no-user-select();
  }
}
