/*
  Styles for the Manage Poviders Page
  ------------------------------------------------------------------------------
*/

$provider--id-width: 60px;
$provider--scheme-width: 150px;
$provider--provider-width: 150px;
$provider--providerorg-width: 210px;
$provider--redirect-width: 220px;
$provider--delete-width: 30px;


.provider--id {width: $provider--id-width;}
.provider--scheme {width: $provider--scheme-width;}
.provider--provider {width: $provider--provider-width;}
.provider--providerorg {width: $provider--providerorg-width;}
.provider--redirect {width: $provider--redirect-width;}
.provider--delete {
  width: $provider--delete-width;
  min-width: $provider--delete-width;
}
.provider--arrow {flex: 1 0 0;}

.fancytable--td.provider--id,
.fancytable--th.provider--id {
  padding: 0 8px;
}
.provider--redirect.deleting {
  width: ($provider--redirect-width - $fancytable--table--margin - $provider--delete-width);
}

.provider--arrow {
  display: flex;
  align-items: center;
  min-width: 36px;

  &.fancytable--td {
    padding: 0 8px;
  }

  > span {
    position: relative;
    height: 2px;
    width: 100%;
    @include gradient-h($c-pool,$c-star);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $c-pool;
      left: 0;
    }
    &:after {
      right: -6px;
      border-style: solid;
      border-color: transparent;
      border-width: 6px;
      border-left-color: $c-star;
    }
  }
}
