/*
  Styles for Fancy Tables
  ------------------------------------------------------------------------------
*/

$fancytable--table--margin: 4px;

.fancytable--row,
.fancytable--labels {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  > div:not(.confirm-buttons) {
    margin-right: $fancytable--table--margin;
  }
}
.fancytable--row {
  margin-bottom: $fancytable--table--margin;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.fancytable--labels {
  border-bottom: 2px solid $g5-pepper;
  margin-bottom: 10px;
  @include no-user-select();
}
.fancytable--th,
.fancytable--td {
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  font-size: 13px;
}
.fancytable--th {
  color: $g17-whisper;
  padding: 0 11px;

  &:last-of-type {
    margin-right: 0;
  }
}
.fancytable--td {
  display: flex;
  align-items: center;
  color: $g13-mist;
}
