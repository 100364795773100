/*
    Styles for TICKscript Editor
    ----------------------------------------------------------------------------
*/

$tickscript-controls-height: 60px;

.tickscript {
  flex: 1 0 0;
  position: relative;
}
.tickscript-controls,
.tickscript-console,
.tickscript-editor {
  width: 100%;
}
.tickscript-console,
.tickscript-controls {
  padding: 0 60px;
  display: flex;
}
.tickscript-controls {
  align-items: center;
  height: $tickscript-controls-height;
  justify-content: space-between;
  background-color: $g3-castle;
}
.tickscript-controls--name {
  margin: 0;
  letter-spacing: 0;
  @include no-user-select();
  font-size: 17px;
  font-weight: 400;
  color: $g13-mist;
}
.tickscript-controls--right {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  > * {margin-left: 8px;}
}
.tickscript-console {
  align-items: flex-start;
  height: $tickscript-controls-height * 2.25;
  border-top: 2px solid $g3-castle;
  background-color: $g0-obsidian;
  overflow-y: scroll;
  @include custom-scrollbar($g0-obsidian,$g4-onyx);

  > p {
    position: relative;
    padding-left: 16px;
    font-family: $code-font;
    margin: 11px 0;
    font-weight: 700;
    word-wrap: break-word;
    word-break: break-word;

    &:before {
      content: '>';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.tickscript-console--default {
  color: $g13-mist;
}
.tickscript-console--valid {
  color: $c-rainforest;
}
.tickscript-console--error {
  color: $c-dreamsicle;
}
.tickscript-editor {
  height: calc(100% - #{$tickscript-controls-height * 3.25});
}

/*
    Toggle for displaying Logs
    ----------------------------------------------------------------------------
*/
.logs-toggle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  > li {
    width: 100px;
    justify-content: center;
  }
  > li:not(.active) {
    background-color: $g0-obsidian;

    &:hover {
      background-color: $g3-castle;
    }
  }
}
