/*
  Styles for Overlay Technology (aka Cell Edit Mode)
  ------------------------------------------------------
*/

$overlay-controls-height: 60px;
$overlay-controls-bg: $g2-kevlar;
$overlay-z: 100;

.overlay-technology {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: $overlay-z;
  padding: 0 30px;

  /*
    Semi-transparent gradient in background
    Makes it possible to leave opacity alone
  */
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include gradient-diag-down($c-pool,$c-comet);
    opacity: 0.7;
    z-index: -1;
  }

  .overlay-controls {
    padding: 0 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 $overlay-controls-height;
    width: 100%;
    left: 0;
    border: 0;
    background-color: $g2-kevlar;
  }
  .overlay-controls .nav-tablist {
    width: 230px;

    li {
      white-space: nowrap;
      justify-content: center;
      flex: 1 0 50%;
     }
  }
  .overlay-controls--right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    flex: 1 0 0;

    .toggle {
      margin: 0 0 0 5px;
    }
    p {
      font-weight: 600;
      color: $g13-mist;
      margin: 0 6px 0 0;
      @include no-user-select;
      white-space: nowrap;
    }
  }
  .overlay--graph-name {
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    text-transform: uppercase;
    @include no-user-select;
  }
}
.overlay-technology--editor {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.overlay-technology--editor .query-maker--empty {
  margin-bottom: 8px;
}
.overlay-controls .confirm-buttons {
  margin-left: 32px;
}

/* Graph editing in Dashboards is a little smaller so the dash can be seen in the background */
.overlay-technology .graph {
  margin: 0 15%;
}
.overlay-technology .query-maker {
  flex: 1 0 0%;
  padding: 0 18px;
  margin: 0;
  background-color: $g2-kevlar;
}
.overlay-technology .query-maker--tabs {
  margin-top: 0;
}
.overlay-technology .query-maker--tab-contents {
  margin-bottom: 8px;
}
