/*
    Query Builder
    -------------------------------------------------------------
    NOTE: Variables are located in query-maker.scss
*/
.query-builder {
  width: 100%;
  flex: 1 0 0%;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
}
.query-builder--column {
  display: flex;
  flex-direction: column;
  flex: 2 0 0%;
}
.query-builder--column-db {
  flex: 1 0 0%;
}
.query-builder--heading {
  @include no-user-select();
  width: 100%;
  height: $query-builder--heading-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: $query-builder--heading-text;
  background-color: $query-builder--heading-bg;
  padding: 0 $scrollbar-width 0 $query-builder--list-gutter;
  line-height: $query-builder--heading-height;

  /* Accounting for headings w/ optional second item */
  & > span {
    margin-right: 16px;
  }
}
.query-builder--list,
.query-builder--list-empty {
  flex: 1 0 0%;
}
.query-builder--list {
  padding: 0;
  background-color: $query-builder--list-bg;
}
.query-builder--list-empty {
  background-color: $query-builder--list-bg;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $query-builder--list-empty-text;

  &,
  & * {
    @include no-user-select();
  }
}
.query-builder--list-item {
  @include no-user-select();
  background-color: $query-builder--list-item-bg;
  color: $query-builder--list-item-text;
  height: $query-builder--list-item-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  padding: 0 $query-builder--list-gutter;
  transition: color 0.25s ease, background-color 0.25s ease;

  &:hover {
    background-color: $query-builder--list-item-bg-hover;
    color: $query-builder--list-item-text-hover;
    cursor: pointer;
  }
  /* Active State */
  &.active {
    background-color: $query-builder--list-item-bg-active;
    color: $query-builder--list-item-text-active;
  }
  /* Hide dropdowns unless item is active */
  & > .dropdown {
    visibility: hidden;
  }
  &.active > .dropdown {
    visibility: visible;
  }
  /* Sub-item group for layout purposes */
  & > span {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-right: 8px;
  }
}
/* Filter Element */
.query-builder--filter {
  flex: 1 0 0%;
  display: flex;

  & > span {
    font-size: 12px;
    line-height: 30px;
    order: 1;
    width: 20px;
    margin-right: -20px;
    text-align: right;
    position: relative;
    z-index: 2;
    transition: color 0.25s ease;
    color: $g9-mountain;
  }
  & > input.form-control {
    order: 2;
    padding-left: 24px;
    border-color: $g6-smoke !important;

    &:hover {
      border-color: $g7-graphite !important;
    }
    &:focus {
      border-color: $c-pool !important;
    }
    &:focus + span {
      color: $c-pool;
    }
  }
}
/* Checkbox Element */
.query-builder--checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: $g2-kevlar;
  position: relative;
  margin-right: 5px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1, 1);
    width: 20px;
    height: 20px;
    opacity: 0;
    background-color: $c-pool;
    border-radius: 50%;
    transition: transform 0.25s ease, opacity 0.25s ease;
  }
}
.query-builder--list-item.active .query-builder--checkbox:after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0.4, 0.4);
}
/* Caret Element */
.query-builder--caret {
  margin-right: 5px;
  transform: rotate(0deg);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.query-builder--list-item.active .query-builder--caret {
  transform: rotate(90deg);
}
/* Sub lists (Used for the tags list) */
.query-builder--sub-list {
  padding: 4px $query-builder--list-gutter 4px $query-builder--sub-list-gutter;
  background-color: $query-builder--sub-list-bg;

  .query-builder--list-item {
    padding: 0;
  }
  .query-builder--filter {
    margin-bottom: 4px;
  }
}
/* Toggle for grouping by tags in tags list */
.group-by-tag {
  visibility: hidden;

  &.active {
    visibility: visible;
    background: $c-pool;
    color: $g20-white;

    &:hover {
      background: $c-laser;
      color: $g20-white;
    }
  }
}
.query-builder--list-item:hover .group-by-tag,
.query-builder--list-item.active .group-by-tag,
.query-builder--list-item .group-by-tag.btn-primary {
  visibility: visible;
}
.query-builder--db-dropdown {
  display: inline-block;
}
/* Container for group by and fill dropdowns to live side-by-side */
.query-builder--groupby-fill-container {
  display: flex;
  alignItems: center;
}
