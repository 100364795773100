/*
    Table Type Graphs in Dashboards
    ----------------------------------------------------------------------------
*/

.table-graph-container {
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 16px);
  top: 8px;
  left: 16px;
  border: 2px solid $g5-pepper;
  border-radius: 3px;
  overflow: hidden;
}

.table-graph-cell {
  line-height: 28px; // Cell height - 2x border width
  padding: 0 6px;
  font-size: 13px;
  color: $g12-forge;
  border: 1px solid $g5-pepper;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // Highlight
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(255,255,255,0.2);
    visibility: hidden;
    box-sizing: border-box;
  }

  &__numerical {
    font-family: $code-font;
  }
  &__fixed-row,
  &__fixed-column {
    font-weight: 700;
    color: $g14-chromium;
    background-color: $g4-onyx;
  }
  &__fixed-row {
    border-top: 0;
  }
  &__fixed-column {
    border-left: 0;
  }
  &__fixed-corner {
    font-weight: 700;
    border-top: 0;
    border-left: 0;
    color: $g18-cloud;
    background-color: $g5-pepper;
  }
  &__highlight-row:after,
  &__highlight-column:after {
    visibility: visible;
  }
  &__highlight-row.table-graph-cell__highlight-column {
    border-color: $g20-white;
    &:after {visibility: hidden;}
  }
  &__field-name {
    padding-right: 17px;

    &:before {
      font-family: 'icomoon';
      content: "\e902";
      font-size: 17px;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      font-size: 13px;
      opacity: 0;
      transition:
        opacity 0.25s ease,
        color 0.25s ease,
        transform 0.25s ease;
    }
    &:hover {
      cursor: pointer;
    }
    &:hover:before {
      opacity: 1;
    }
  }
  &__sort-asc,
  &__sort-desc {
    color: $c-pool;

    &:before {
      opacity: 1;
    }
  }
  &__sort-asc:before {
    transform: translateY(-50%) rotate(0deg);
  }
  &__sort-desc:before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.ReactVirtualized__Grid {
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &.table-graph--scroll-window {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      &-button {
        background-color: $g5-pepper;
      }
      &-track {
        background-color: $g5-pepper;
      }
      &-track-piece {
        background-color: $g5-pepper;
        border: 2px solid $g5-pepper;
        border-radius: 5px;
      }
      &-thumb {
        background-color: $g11-sidewalk;
        border: 2px solid $g5-pepper;
        border-radius: 5px;
      }
      &-corner {
        background-color: $g5-pepper;
      }
    }
    &::-webkit-resizer {
      background-color: $g5-pepper;
    }
  }
}
